import React from "react"
import { Helmet } from "react-helmet"

const StructuredDataNews = ({ richsnippetsnews = {} }) => {

  return (
    <Helmet>
      {(richsnippetsnews.valido ? true : null) && (
        <script type="application/ld+json">
        {JSON.stringify({
        "@context": "https://schema.org",
        "@type": "NewsArticle",
        "mainEntityOfPage": {
          "@type": "WebPage",
          "@id": (process.env.GATSBY_ROOT_URL || "http://localhost:8000/") + richsnippetsnews.urlnews
        },
        "headline": richsnippetsnews.titulo,
        "image": [
          (process.env.GATSBY_ROOT_URL || "http://localhost:8000/") +
              richsnippetsnews.imagen.localFile.publicURL,
        ],
        "datePublished": richsnippetsnews.fechapublicado,
        "dateModified": richsnippetsnews.fechamodificado,
        "author": {
          "@type": "Person",
          "name": "Paola Procell",
          "url":"https://www.lacocinasana.com/quienes-somos"
        },
        "publisher": {
          "@type": "Organization",
          "name": "LA COCINA SANA",
          "logo": {
          "@type": "ImageObject",
          "url": "https://recetascocinasana.s3.eu-west-1.amazonaws.com/newlogoamarillo_9af20f0fe3.svg"
          }
        }

        })}
        </script>
      )}
    </Helmet>
  )
}

export default StructuredDataNews