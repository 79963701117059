import React from "react"
import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql , Link} from "gatsby";
import { ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/solid'

const Anteriorsiguientenoticias = ({ slugnoticia }) => {
  const data = useStaticQuery(graphql`
    {
        listadosnoticias: allStrapiNoticiasConsejosTrucos(
            sort: {fields: published_at, order: DESC}
            filter: {categoria_noticia: {slug: {eq: "cocina-sana"}}}
            ) {
            edges {
              next {
                slug
                titulo
                seo {
                    shareImage {
                      localFile {
                        childImageSharp {
                          gatsbyImageData (placeholder: NONE, quality: 7, sizes: "1080")
                        }
                      }
                    }
                  }
              }
              node {
                slug
                titulo
                seo {
                    shareImage {
                      localFile {
                        childImageSharp {
                          gatsbyImageData (placeholder: NONE, quality: 7, sizes: "1080")
                        }
                      }
                    }
                  }
              }
              previous {
                slug
                titulo
                seo {
                    shareImage {
                      localFile {
                        childImageSharp {
                          gatsbyImageData (placeholder: NONE, quality: 7, sizes: "1080")
                        }
                      }
                    }
                  }
              }
            }
          }
    }
  `)
//   return <pre>{JSON.stringify(data, null, 4)}</pre>}

    const listadonoticia = data.listadosnoticias.edges;
      return (  
      <div className="bg-white">
          <div className="mx-auto py-2 px-0 max-w-7xl sm:px-0 lg:px-0">
          <div className="grid grid-cols-1">
              <div>
                    {listadonoticia.filter(noticia => noticia.node.slug == slugnoticia).map(noticiafiltrada => (
                        <div key={noticiafiltrada.node.slug}>
                        {noticiafiltrada.previous && 
                        <div>
                            <div className="fixed top-1/3 hidden xl:block xl:left-2 2xl:left-30 md:shadow-lg">
                                <Link to={`/cocina-sana/${noticiafiltrada.previous.slug}`}>           
                                    <GatsbyImage
                                        alt={`${noticiafiltrada.previous.titulo}`}
                                        image={
                                            noticiafiltrada.previous.seo.shareImage.localFile.childImageSharp
                                        .gatsbyImageData
                                        }
                                        className="w-32 object-cover aspect-w-6 aspect-h-4"
                                    />
                                    <div className="">
                                    <div className="text-black font-medium text-s12 pt-1 px-2 pb-1 w-32 leading-4 line-clamp-3">{noticiafiltrada.previous.titulo}</div>
                                    </div>
                                </Link>
                            </div>
                            <div className="fixed bottom-0 xl:hidden bg-amarillosiguiente left-0 w-1/2  py-4 px-2 border-r-2 border-white">
                                <Link to={`/cocina-sana/${noticiafiltrada.previous.slug}`}>
                                    <div className="flex items-center">
                                    <ChevronLeftIcon className="w-6 text-gray-400 text-left" aria-hidden="true" /><span className="text-black font-medium text-s12 pt-0 uppercase line-clamp-2 md:line-clamp-none ml-1 underline w-36">{noticiafiltrada.previous.titulo}</span> 
                                    </div>
                                </Link>
                            </div>
                        </div>
                        }

                        {noticiafiltrada.next && 
                            <div>
                                <div className="fixed top-1/3 hidden xl:block xl:right-2 2xl:right-30 md:shadow-lg">
                                    <Link to={`/cocina-sana/${noticiafiltrada.next.slug}`}>
                                    <GatsbyImage
                                            alt={`${noticiafiltrada.next.titulo}`}
                                            image={
                                                noticiafiltrada.next.seo.shareImage.localFile.childImageSharp
                                            .gatsbyImageData
                                            }
                                            className="w-32 object-cover aspect-w-6 aspect-h-4"
                                        />
                                        <div className="">
                                        <div className="text-black font-medium text-s12 pt-1 px-2 pb-1 w-32 leading-4 line-clamp-3">{noticiafiltrada.next.titulo}</div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="fixed bottom-0 bg-amarillosiguiente xl:hidden right-0 w-1/2  py-4 px-2">
                                    <Link to={`/cocina-sana/${noticiafiltrada.next.slug}`}>
                                       
                                        <div className="flex items-center float-right place-items-end">
                                            <span className="text-black font-medium text-s12 pt-0 text-right uppercase line-clamp-2 md:line-clamp-none mr-1 underline w-36">{noticiafiltrada.next.titulo}</span> <ChevronRightIcon className=" w-6 text-gray-400 text-right" aria-hidden="true" />
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        }          
                        </div>
                    ))}
                </div>
          </div>
          </div>
      </div>
  )
        
}     

    export default Anteriorsiguientenoticias;
